import { H1 } from "components/fonts";
import { ReactNode } from "react";
import "./style.scss";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
export const Page = (props: {
  title?: string;
  children?: ReactNode;
  actions?: ReactNode;
  noHeader?: boolean;
  metaTags?: {
    title: string;
    description: string;
    keywords: string;
    canonicalUrl: string;
  };
}) => {
  const { noHeader = false, metaTags } = props;
  const { t } = useTranslation();
  return (
    <section className={`page-layout ${noHeader ? "no-header" : null}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTags?.title ?? t("MetaTags:Title.Home")}</title>
        <link
          rel="canonical"
          href={metaTags?.canonicalUrl ?? "https://globalite.app"}
        />
        <meta
          name="description"
          content={metaTags?.description ?? t("MetaTags:Description.Home")}
        />
        <meta
          name="keywords"
          content={metaTags?.keywords ?? t("MetaTags:Keywords.Home")}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="content">
        <div className="page-header">
          {props.title && <H1 message={props.title} />}
        </div>
        <div>{props.children}</div>
      </div>
    </section>
  );
};

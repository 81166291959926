import { gql, useMutation } from "@apollo/client";
import { ProfileType } from "models/Profile";

const OAUTH_LOGIN = gql`
  mutation Oauth($token: String!) {
    oauth(token: $token) {
      token
      profile {
        id
        name
        surname
        aboutMe
        nickname
        photoUrl
        stripeAccountId
        stripeAccountReady
        customerId
        account {
          id
          email
          agreement
        }
      }
    }
  }
`;

export const useOauthLogin = () => {
  const [oauthLogin, { loading, error }] = useMutation<{
    oauth: {
      token: string;
      profile: ProfileType;
    };
  }>(OAUTH_LOGIN);
  return { oauthLogin, loading, error };
};

import * as Sentry from "@sentry/react";

type InitSentryLoggingOptions = {
  dsn: string;
  apiUrl: string;
};

const initSentryLogging = ({ dsn, apiUrl }: InitSentryLoggingOptions) => {
  Sentry.init({
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [apiUrl],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export default initSentryLogging;

import "./style.scss";
export const Loader = () => {

    return (
        <div className="loader-div">
            <div className="loader">
                <div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    );
}



import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "antd";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Typography } from "@mui/material";

export const BaseHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <Row
      className="base-header"
      justify="space-between"
      style={{ height: "100%" }}
      align="middle"
      wrap={false}
    >
      <Col id="header-back-btn">
        {location.pathname !== "/" && (
          <Button className="btn" onClick={navigateBack} style={{}}>
            <div className="back-btn">
              <IoIosArrowBack size={30} />
              <Typography className="text" variant="button">
                {t("Home:Back")}
              </Typography>
            </div>
          </Button>
        )}
      </Col>
    </Row>
  );
};

import { Col, Row } from "antd";
import { Page } from "components/layouts";
import { Loader } from "components/loader/loader";


export const LoadingPage = () => {

    return (
        <Page>
            <Col>
                <Row align="middle" justify="center">
                    <div style={{height: "80vh", display: "flex", alignItems: "center"}}>
                        <Loader />
                    </div>
                </Row>
            </Col>
        </Page>
    );
}








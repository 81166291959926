import "antd/dist/antd.css";
import { ApolloClientProvider } from "components/apolloClient/apolloClient";
import { AuthProvider } from "components/auth/authProvider";
import { CookieConsentModal } from "components/modals/CookieConsent/CookieConsent";
import { LazyRouter } from "components/router/LazyRouter";
import { RouterProvider } from "components/router/RouterProvider";
import { routes } from "components/router/routes";
import { StripeProvider } from "components/stripe/StripeProvider";
import ErrorBoundary from "pages/Error/ErrorPage";

function App() {
  // if (window) {
  //   (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__ = null;
  // }

  const mobilePlatform = (window as any).mobilePlatform;
  return (
    <ErrorBoundary>
      <ApolloClientProvider>
        <RouterProvider>
          <AuthProvider>
            <StripeProvider>
              <LazyRouter routes={routes} />
              {mobilePlatform !== "ios" && mobilePlatform !== "android" && <CookieConsentModal />}
            </StripeProvider>
          </AuthProvider>
        </RouterProvider>
      </ApolloClientProvider>
    </ErrorBoundary>
  );
}

export default App;

import { CategoryTreeNode } from "models/Category";
import { OrderType } from "models/Order";
import { TFunction } from "react-i18next";
import { ColorType } from "./types";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { ProfileType } from "models/Profile";

export const IsAdminOrSupport = (profile: ProfileType | null) => {
  return (
    profile?.account?.roles?.includes("admin") ||
    profile?.account?.roles?.includes("support")
  );
};

export const uploadImageToS3 = async (
  signedUrl: string,
  file: File,
  options?: { maxAge?: number }
) => {
  await fetch(signedUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Cache-Control": `max-age=${options?.maxAge ?? "31536000"}`,
    },
  });
};

export const rentingTime = (order: OrderType) => {
  let total = 0;
  const duration = order.duration;
  const startAt = order.startAt;
  const timeUnit = order.post.timeUnit;
  switch (timeUnit) {
    case "minute":
      total = duration;
      break;
    case "hour":
      total = duration * 60;
      break;
    case "day":
      total = duration * 60 * 24;
      break;
    case "week":
      total = duration * 60 * 24 * 7;
      break;
    default:
      total = duration;
      break;
  }

  return new Date(startAt).getTime() + total * 60 * 1000;
};

export const convertDataUrlToBlob = (dataUrl: string): string => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime || "" });
  const objectURL = URL.createObjectURL(blob);

  return objectURL;
};

export const humanizeDate = (date: Date | string | number): string => {
  return new Date(date).toLocaleString(
    localStorage.getItem("i18nextLng") || "en-EN",
    {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }
  );
};

export const mergeArrays = (existing: any[], incoming: any[]) => {
  const map: Record<string, any> = {};
  const a = [...existing, ...incoming];
  for (const post of a) {
    map[post.__ref] = post;
  }
  return Object.values(map);
};

export const translateTreeData = (
  tree: CategoryTreeNode[] = [],
  t: TFunction
) => {
  const res = tree?.map((node: CategoryTreeNode) => {
    return {
      title: t(`Categories:${node.title}` as any),
      value: node.value,
      selectable: node.selectable,
      children: node.children?.map((child: CategoryTreeNode) => {
        return {
          title: t(`Categories:${child.title}` as any),
          value: child.value,
          selectable: child.selectable,
        };
      }),
    };
  });
  return res;
};

export const parseColorOptions = (options: ColorType[], t: TFunction) => {
  return options.map((color) => {
    return {
      id: color,
      label: t(`Colors:${color}`),
    };
  });
};

export const parseExtraAttributes = (extraAttributes: {
  [key: string]: any;
}) => {
  if (!extraAttributes) return [];
  return Object.entries(extraAttributes)
    .filter(([_, value]: any) => value !== undefined)
    .map(([key, value]: any) => {
      return {
        id: key,
        value: { value: String(value) },
      };
    });
};

export const parseCurrency = (currency: string) => {
  const currencyMap: Record<string, string> = {
    USD: "$",
    EUR: "€",
    GBP: "£",
  };

  return currencyMap[currency] || currency;
};

export const groupReports = (reports: any[]) => {
  const groupedReports = reports.reduce((acc, report) => {
    const key = report.postId || report.accountId || report.reviewId;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(report);
    return acc;
  }, {});
  return Object.values(groupedReports);
};

export const parseDateAndTime = (date1: Date, date2: Date) => {
  const year = date1.getFullYear();
  const month = date1.getMonth();
  const day = date1.getDate();
  const hours = date2.getHours();
  const minutes = date2.getMinutes();
  const seconds = date2.getSeconds();
  const milliseconds = date2.getMilliseconds();

  // Calculate the UTC timestamp for the selected time
  const selectedTimeUtc = Date.UTC(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds
  );

  // Calculate the local time zone offset for the selected date
  const localTimezoneOffset = -date1.getTimezoneOffset() * 60000; // convert minutes to milliseconds

  // Subtract the local time zone offset from the UTC timestamp to get the local timestamp
  const selectedTimeLocal = selectedTimeUtc - localTimezoneOffset;

  // Create a new Date object using the local timestamp
  const selectedDate = new Date(selectedTimeLocal);

  return selectedDate;
};

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const downloadImage = async (
  url: string,
  filename: string
): Promise<void> => {
  try {
    const blob = await fetchImage(url);
    saveAs(blob, filename);
  } catch (error) {
    console.error("Error downloading image:", error);
  }
};

export const fetchImage = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Error fetching image from ${url}`);
  }

  const contentType = response.headers.get("content-type");
  if (!contentType || !contentType.startsWith("image/")) {
    throw new Error("Invalid content-type. Expected an image.");
  }

  return await response.blob();
};

export const downloadImagesInFolder = async (
  imageList: { url: string; filename: string }[],
  folderName: string
): Promise<void> => {
  try {
    const zip = new JSZip();

    const imagePromises = imageList.map(async (image) => {
      const blob = await fetchImage(image.url);
      zip.file(`${folderName}/${image.filename}`, blob);
    });

    await Promise.all(imagePromises);

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${folderName}.zip`);
  } catch (error) {
    console.error("Error downloading images:", error);
  }
};

export const nDaysOlder = (n: number, date?: Date) => {
  if (!date) return false;
  const twoDays = n * 24 * 60 * 60 * 1000;
  const now = new Date();
  return now.getTime() - date.getTime() > twoDays;
};

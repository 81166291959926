// import React from 'react'
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./services/i18next/i18n";
import { initLogging } from "modules/logging";
import ReactGA from "react-ga4";

initLogging();
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

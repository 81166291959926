const en = {
  MetaTags: {
    Title: {
      Home: "Globalite",
      Profile: "Globalite - Profile",
      Post: "Globalite - Post",
    },
    Description: {
      Home: "Find and rent what you need, or rent out what you don't use! Safe, convenient, and easy rentals between individuals with our new app. Introducing Globalite, the revolutionary platform for renting all kinds of products between individuals. Whether you need a camera for a weekend project, a bicycle for a day's adventure, or a gala dress for an evening, Globalite connects you with people in your area who have exactly what you need. On the other hand, if you have items you're not using, why not put them to work for you? You can list your own items for rent, set your rates and conditions, and earn extra money. Our secure payment system protects both renters and landlords, and our customer service team is always ready to assist. Download Globalite now and discover a new way to rent!",
    },
    Keywords: {
      Home: "Globalite, rent, rental, product, app, day, new, find, owner, lessor, chat, free, easy, safe, individuals",
    },
  },
  Block: {
    BlockUser: "Block user",
    Blocked: "Blocked",
    BlockInfo:
      "You have blocked this user or this user has blocked you.\nPlease, check your block list.",
    BlockQuestion: "Are you sure you want to block this user?",
    UnblockQuestion: "Are you sure you want to unblock this user?",
    BlockList: "Block list",
    Blockeds: "Blockeds",
    Unblock: "Unblock",
    NoBlocks: "You don't have any block user.",
  },
  CheckInOut: {
    Status: {
      checked_in: "Checked in",
      checked_out: "Checked out",
      checkin_requested: "Checkin requested",
      checkout_requested: "Checkout requested",
    },
  },
  Payment: {
    Authorization: "Payment authorization",
    AuthorizationDescription:
      "Please authorize the payment. It may be done in an external app.",
    WaitingAuthorization: "Waiting for authorization...",
  },
  CookieConsent: {
    Text: "This website uses cookies to enhance the user experience.",
    SecondText:
      "Cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors.",
    AcceptNecessary: "Accept necessary cookies",
    AcceptAll: "Accept all cookies",
  },
  Admin: {
    CancelBail: "Cancel bail",
    promotionRate: "Promotion rate (€/week)",
    ConfirmPaymentQuestion: "Are you sure you want to confirm this payment?",
    ConfirmPayment: "Confirm payment",
    ChargeParcialBail: "Charge parcial bail",
    ChargeBailQuestion: "Are you sure you want to charge this bail?",
    ChargeTotalBail: "Charge total bail",
    CancelPayment: "Cancel payment",
    CancelPaymentQuestion: "Are you sure you want to cancel this payment?",
    ResolveIncidences: "Resolve incidences",
    ResolveOrderIncidences: "Resolve order incidences",
    DownloadImages: "Download images...",
    OrdersWithIncidences: "Orders with incidences",
    Incidences: "Incidences",
    FinnishOrder: "Finish order",
    ManageOrderQuestion: "Are you sure you want to manage this order?",
    AcceptOrder: "Accept order",
    RejectOrder: "Reject order",
    DeleteOrder: "Delete order",
    CancelOrder: "Cancel order",
    ManageOrder: "Manage order",
    commission: "Commission",
    Month: "Month",
    stripe: "Stripe",
    seller: "Seller",
    revenue: "Revenue",
    stripeBail: "Bail",
    Orders: "Orders",
    Revenue: "Revenue",
    Total: "Total",
    Accepted: "Accepted",
    Pending: "Pending",
    Rejected: "Rejected",
    Finished: "Finished",
    Cancelled: "Cancelled",
    accepted: "Accepted",
    pending: "Pending",
    rejected: "Rejected",
    finished: "Finished",
    cancelled: "Cancelled",
    TotalRevenue: "Total revenue",
    TotalComission: "Total comission",
    Seller: "Seller",
    Buyer: "Buyer",
    Messages: "Messages",
    DeleteReportQuestion: "Are you sure you want to delete this report?",
    Bookings: "Bookings",
    Stats: "Stats",
    Chats: "Chats",
    Reports: "Reports",
    Users: "Users",
    NotAdmin: "You are not an admin",
    ConfigParams: "Config params",
    stripeComission: "Stripe comission",
    jwtTokenSecret: "JWT token secret",
    AccountReports: "Account",
    PostReports: "Post",
    ReviewReports: "Review",
    PostReportsModal: "Post reports",
    AccountReportsModal: "Account reports",
    ReviewReportsModal: "Review reports",
    ReportedTimes: "Reported times: {{count}}",
    Cancel: "Cancel",
    Delete: "Delete",
    Reason: "Reason",
    ReportQuestion: "Are you sure you want to send this report?",
  },
  Filters: {
    SearchedPosts: "Showing results for '{{search}}'. Total: {{total}}",
    NoPostsFiltered: "We couldn't find any posts with those filters.",
    SelectCategory: "Choose a category to filter by:",
    From: "From",
    To: "To",
    All: "All",
    Filter: "Filter",
    red: "Red",
    blue: "Blue",
    green: "Green",
    yellow: "Yellow",
    orange: "Orange",
    purple: "Purple",
    black: "Black",
    white: "White",
    grey: "Grey",
    brown: "Brown",
    pink: "Pink",
    silver: "Silver",
    gold: "Gold",
    gasoline: "Gasoline",
    diesel: "Diesel",
    hybrid: "Hybrid",
    electric: "Electric",
    yes: "Yes",
    no: "No",
    Yes: "Yes",
    No: "No",
    other: "Other",
  },
  Footer: {
    Profile: "Profile",
    Home: "Home",
    Orders: "Orders",
    Admin: "Admin",
  },
  Stripe: {
    SetUpIntent: {
      Succeded: "Your card has been added successfully",
      Processing: "Processing...",
      Error: "There was an error adding your card. Please try again later.",
    },
    CreateExpressAccount: {
      Title: "Create express account",
      Description:
        "You need to create a Stripe account to be able to create posts and receive money from your customers. Please click on the button below to redirect to Stripe platform for your registration.",
      GoStripe: "Go to Stripe",
    },
    PaymentMethods: {
      Title: "Payment methods",
      Hide: "Hide",
      Pay: "Pay",
      AddNew: "Add new payment method",
      Select: "Select a payment method to pay for your order",
    },
  },

  Order: {
    Approval: "Approval",
    Request: "Request",
    PaymentDate: "Payment date",
    PaymentId: "Payment ID",
    PaymentCard: "Payment card",
    ProductPrice: "Product price",
    OrderPrice: "Order price",
    ManagementCost: "Management cost",
    StripeCost: "Dynamic costs",
    OrderStatus: {
      accepted: "ACCEPTED",
      pending: "PENDING",
      rejected: "REJECTED",
      finished: "FINISHED",
      canceled: "CANCELED",
      no_payment: "NO PAYMENT",
      requires_action: "REQUIRES ACTION",
      accepted_payment_failed: "ACCEPTED PAYMENT FAILED",
      failed: "FAILED",
    },
    PossiblyRecreatePayments:
      "It is possible that renter can recreate the payments, but if you cancel this order, you will not be able to recreate the payments.",
    CancelOrderQuestion: "Are you sure you want to cancel this order?",
    RecreatePayment: "Recreate payment",
    RentedBy: "Rented by",
    BailClaimed: "Bail claimed",
    ChargeBail: "Charge bail",
    Bail: "Bail",
    ResolveIncidencesQuestion:
      "Are you sure you want to resolve this incidences?",
    CancelPaymentQuestion: "Are you sure you want to cancel this payment?",
    ChargeBailQuestion: "Are you sure you want to charge this bail?",
    ParcialBailQuestion: "How much bail do you want to charge? (Max: {{max}})",
    QRScanner: "QR scanner",
    QRCode: "QR code",
    OrderData: "Order data",
    Incidence: "Incidence",
    CreateIncidence: "Create incidence",
    BailInfo:
      "The bail will be returned to you after the order is finished, unless any incident happens.",
    WhatDayYouWantToRent: "What day do you want to rent this item?",
    WhatTime: "What time?",
    Requests: "Requests",
    CancelOrder: "Cancel order",
    AddPaymentMethod: "Add payment method",
    TimeUntilEnd: "Time to the end",
    TimeUntilStart: "Time to the start",
    Status: "Status",
    Buyer: "Renter",
    Seller: "Lessor",
    Cancelled: "Cancelled",
    Accepted: "Accepted",
    Pending: "Pending",
    Rejected: "Rejected",
    Finished: "Finished",
    NoAccepted: "No accepted orders yet",
    NoPending: "No pending orders yet",
    NoRejected: "No rejected orders yet",
    NoFinished: "No finished orders yet",
    UpdateOrder: "Update order",
    CreateOrder: "Create new order",
    NoOrders: "No orders yet",
    StartAt: "Start at",
    Duration: "Duration",
    HowMuchTime: "How much time do you need?",
    Price: "Price",
    StartTime: "Start time",
    EndTime: "End time",
    Per: "per {{timeUnit}}",
    day: "day",
    hour: "hour",
    minute: "minute",
    week: "week",
    second: "second",
    NumberOfday: "Number of days",
    NumberOfhour: "Number of hours",
    NumberOfminute: "Number of minutes",
    NumberOfweek: "Number of weeks",
    PostPrice: "The post's price is",
    Reservation: "Your reservation",
    TotalPrice: "Total price",
    Details: "Order details",
    ContinueToPayment: "Continue to payment",
  },
  Categories: {
    rollers: "Rollers",
    music_instuments: "Music & Instruments",
    vehicles_motor: "Vehicles & Motor",
    technology_electronics: "Technology & Electronics",
    home_garden: "Home & Garden",
    sports_leisure: "Sports & Leisure",
    fashion_accessories: "Fashion & Accessories",
    enterainment_culture: "Entertainment & Culture",
    housing_premises: "Housing & Premises",
    events_parties: "Events & Parties",
    industry_services: "Industry & Services",
    water_activities: "Water activities",
    cars: "Cars",
    motorcycles: "Motorcycles",
    bicycles: "Bicycles",
    motors_accessories: "Motors & Accessories",
    tv_audio_photo: "TV, Audio & Photo",
    mobiles_telephony: "Mobiles & Telephony",
    computers_electronics: "Computers & Electronics",
    console_videogames: "Console & Videogames",
    appliances: "Appliances",
    furniture: "Furniture",
    water_sports: "Water sports",
    mountain_sports: "Mountain sports",
    snow_sports: "Snow sports",
    football: "Football",
    tennis: "Tennis",
    padel: "Padel",
    basketball: "Basketball",
    voleyball: "Voleyball",
    other_sports: "Other sports",
    fashion: "Fashion",
    jewelry_accessories: "Jewelry & Accessories",
    flamenco_wedding_special: "Flamenco & Wedding & Special dresses",
    costumes: "Costumes",
    cinema_books: "Cinema & Books ",
    music_instruments: "Music & Instruments",
    children_babies: "Children & Babies",
    animals_pets: "Animals & Pets",
    events_weddings_parties: "Events, weddings & parties",
    event_party_furniture: "Event & Party furniture",
    catering: "Catering",
    fishing_hunting: "Fishing & Hunting",
    natucial_vessels: "Natucial vessels",
    nautical_accessories: "Nautical accessories",
    diving_wetsuits: "Diving & Wetsuits",
    travel: "Travel",
    beach: "Beach",
    construction_renovations: "Construction & Renovations",
    industry_agriculture: "Industry & Agriculture",
    services: "Services",
    orthopedic_furniture: "Orthopedic furniture",
    no_motor: "No motor",
    motor: "Motor",
    technology: "Technology",
    pcs: "PCs",
    phones: "Phones",
    realstate: "Real state",
    houses: "Houses",
    flats: "Flats",
    premises: "Premises",
    books: "Books",
    ligthsound: "Light & Sound",
    sports: "Sports",
    material: "Material",
    space: "Space",
    tvs: "TVs",
    other: "Other",
    others: "Others",
    camping_accessories: "Camping & Accessories",
    furniture_sofas: "Furniture & Sofas",
    nautical_boats: "Boats",
  },
  Promotion: {
    PromotionStartEnd:
      "The promotion start date is {{startDate}} and the end date is {{endDate}}.",
    AlreadyPromoted: "This post is already promoted. ",
    Pay: "Pay",
    Days: "Number of days",
    Type: "Promotion type",
    Continue: "Continue",
    TotalPrice:
      "The total price for the promotion is: {{rate}}€ x {{days}} days = {{price}}€",
    PromotePost: "{{postTitle}} promotion",
    RatesDescription:
      "This process can involve additional costs for the advertiser. The rate for the promotion plan is {{rate}}€ per week, that means {{perDay}}€ per day.",
    WhichType: "Which type of promotion would you like?",
    StartDate: "Start date",
    EndDate: "End date",
    HowMuchTime: "How much time do you want to promote your post?",
  },
  Review: {
    NoRating: "No rating",
    TotalReviews: "Total reviews: {{total}}",
    TotalPosts: "Total posts: {{total}}",
    DeleteReview: "Delete review",
    DeleteReviewQuestion: "Are you sure you want to delete this review?",
    Rating: "Rating",
    Review: "Review",
    AddReview: "Add review",
    NoReviews: "No reviews yet.",
    Reviews: "Reviews",
    Message: "Message",
  },
  Post: {
    LocationInput: {
      Description: "You can either search for a location or click on the map",
    },
    Create: "Create post",
    Bail: "Bail",
    DeleteQuestion: "Are you sure you want to delete this {{elem}}?",
    Report: "Report",
    minute: "min",
    hour: "h",
    day: "day",
    Details: "Details",
    PublicationDate: "Publication date",
    Minute: "Minute",
    Hour: "Hour",
    Day: "Day",
    Promote: "Promote",
    Rent: "Rent",
    You: "You",
    PostedBy: "Posted by",
    Delete: "Delete",
    Edit: "Edit",
    Bookings: "Bookings",
    Model: "Model",
    Brand: "Brand",
    UpdatePost: "Update post",
    CreatePost: "Create new post",
    Hps: "HPs",
    Title: "Title",
    DoorsNumber: "Number of doors",
    Description: "Description",
    Price: "Price",
    Category: "Category",
    Images: "Images",
    Currency: "Currency",
    Location: "Location",
    TimeUnit: "Time Unit",
  },
  Home: {
    NoPostsAvailable: "No posts available.",
    CreateOne: "Create one!",
    Recent: "Most recent",
    Old: "Oldest",
    Expensive: "Most expensive",
    Cheap: "Cheapest",
    All: "All",
    Favorites: "Favorites",
    Trending: "Trending",
    Filter: "Filter",
    Search: "Search",
    ForYou: "For you",
    Spotlight: "Spotlight",
    Promoted: "Promoted",
    Welcome: "Welcome",
    Home: "Home",
    About: "About",
    Contact: "Contact",
    Profile: "Profile",
    HomePage: "This is home page",
    Login: "Login",
    Logout: "Logout",
    SignUp: "Sign up",
    Back: "Back",
    CreatePost: "Create post",
    SeeMore: "See more",
  },
  Common: {
    Upload: "Upload",

    Time: "Time",
    Back: "Back",
    DNI: "DNI",
    Skip: "Skip",
    Name: "Name",
    Surname: "Surname",
    Address: "Address",
    BirthDate: "Birth date",
    Gender: "Gender",
    Male: "Male",
    Female: "Female",
    NoGender: "I'd rather not to say",
    Cancel: "Cancel",
    Date: "Date",
    FullName: "Full name",
    Password: "Password",
    Done: "Done",
    EmailAddress: "Email address",
    Continue: "Continue",
    Message: "Message",
    Email: "Email",
    SaveChanges: "Save changes",
  },
  Form: {
    ChangePassword: {
      Title: "Change password",
      ConfirmOld: "Confirm by typing old password",
    },
    ForgotPassword: {
      Title: "Forgot password",
      Question: "Forgot password?",
      Description:
        "Enter your email address and we'll send you a link to reset your password.",
    },
    PasswordConfirmation: "Confirm password",
    SignInTerms: "By signing up, you agree with the",
    TermsAndConditions: "Terms and conditions",
    SignUp: "Sign up",
    AlreadyHaveAccount: "Already have an account?",
  },
  Account: {
    Create: {
      Title: "Create a new account",
    },
    DeleteAccount: "Delete account",
    DeleteAccountModalText1:
      "To delete your account, please type the next phrase below:",
    DeleteAccountModalText2:
      "This action is irreversible. All your data will be deleted.",
    DeleteNickname: "delete {{nickname}}",
  },
  Help: {
    LegalContent: "Legal content",
    TermsAndConditions: {
      Title: "Terms and conditions",
      Content1:
        "Welcome to Globalite. By using our website, you agree to abide by these terms and conditions of use. If you do not agree with these terms and conditions, please do not use our website.",
      Content2:
        "Globalite is an online platform that allows users to post and rent products amongst themselves. We neither own nor sell any product posted on our website. Any transaction you carry out with an advertiser is solely your responsibility.",
      Content3:
        "By using our website, you agree that you are responsible for all the products you post and all the transactions you make. Users agree to use in a responsible, respectful and legal manner. It is strictly prohibited to use the application for illegal, abusive or fraudulent purposes.",
      Content4:
        "Globalite reserves the right to reject or remove any advertisement or user that we deem inappropriate or that violates our terms and conditions of use.",
      Content5:
        "Globalite does not guarantee the quality, safety, or legality of the products posted on our website. You agree that you use our website and conduct any transaction at your own risk.",
      Content6:
        "Globalite is not responsible for any damage or loss arising from your use of our website or any transaction you make with an advertiser.",
      Content7:
        "Globalite reserves the right to modify these terms and conditions of use at any time. Please review these terms and conditions regularly to stay informed of any changes.",
      Content8:
        "These terms and conditions of use are governed by the laws of Spain. Any dispute arising from the use of our website will be resolved exclusively by the courts of Spain.",
      Content9:
        "If you have any questions about these terms and conditions of use, please contact us through",
      OurForm: "our form",
    },
    PrivacyPolicy: "Privacy policy",
    ContactUs: "Contact us",
    Contact: "Contact",
    FAQ: "FAQ",
    Submit: "Submit",
  },
  Profile: {
    UserNotFound: "User not found",
    UserNotFoundInfo: "The user with nickname '{{nickname}}' does not exist.",
    MyIncome: "My income",
    NoFavorites: "You don't have any favorite posts yet",
    MyFavorites: "My favorite posts",
    UserPosts: "{{name}} posts",
    EditProfile: "Edit profile",
    MyPosts: "My posts",
    MyBookings: "My bookings",
    NeedHelp: "Need help?",
    CreateProfile: "Create profile",
    AboutMe: "About me",
    ZipCode: "Zip code",
    City: "City",
    ProfileImage: "Profile image",
    ProfileImageFor: "Profile image for",
    LogOut: "Log out",
  },

  Auth: {
    NeedLogin: "Login required",
    Required: "You need to be logged in to access this page",
    SignIn: {
      RememberMe: "Remember me",
      Title: "Sign in",
      Continue: "Sign in to continue",
      With: "Sign in with",
      Description:
        "Enter your email address and password to access your account.",
    },
  },

  Google: {
    SignIn: "Sign in with Google",
  },

  Apple: {
    SignIn: "Sign in with Apple",
  },

  ExtraAttributes: {
    length: "Length",
    width: "Width",
    height: "Height",
    Distance: "Distance",
    mileage: "Mileage",
    year: "Year",
    color: "Color",
    fuel: "Fuel",
    model: "Model",
    doorsnumber: "Doors",
    seatsnumber: "Seats",
    brand: "Brand",
    cvs: "CVs",
    cpu: "CPU",
    ram: "RAM",
    storage: "Storage",
    screen: "Screen",
    os: "OS",
    screensize: "Screen size",
    screenresolution: "Screen resolution",
    gpu: "GPU",
    cpucores: "CPU cores",
    camerapixels: "Camera pixels",
    furnished: "Furnished",
    elevator: "Elevator",
    publisher: "Publisher",
    edition: "Edition",
    bathroomsnum: "Bathrooms",
    floorsnum: "Floors",
    roomsnum: "Rooms",
    area: "Area",
    roofed: "Roofed",
    smarttv: "Smart TV",
    size: "Size",
    parking: "Parking",
  },
  Error: {
    SomethingWentWrong: "Oops! Something went wrong",
    TryAgainLater: "Please, try again later",
    Reload: "Reload",
    GoHome: "Go to home page",

    RequiredRating: "The rating is required",
    RequiredLocation: "The location is required",
    RequiredName: "The name is required",
    RequiredSurname: "The surname is required",
    RequiredType: "The type is required",
    RequiredDate: "The date is required",
    RequiredEmail: "The email is required",
    RequiredMessage: "The message is required",
    RequiredStartDate: "The start date is required",
    RequiredDuration: "The duration is required",
    RequiredTitle: "The title is required",
    MinTitle: "The title must be at least {{min}} characters long",
    RequiredDescription: "The description is required",
    MinDescription: "The description must be at least {{min}} characters long",
    RequiredCategory: "The category is required",
    RequiredPrice: "The price is required",
    RequiredCurrency: "The currency is required",
    RequiredTimeUnit: "The time unit is required",
    RequiredReason: "The reason is required",

    EmailAlreadyInUse: "The email address is already in use",
    InvalidEmail: "The email address is invalid",
    InvalidPassword: "The password is invalid",
    InvalidPasswordConfirmation: "The password confirmation is invalid",
    PasswordTooShort: "The password is too short",
    PasswordTooLong: "The password is too long",
    PasswordsDoNotMatch: "Passwords do not match",
    UserNotFound: "The user was not found",
    WrongPassword: "The password is wrong",
    AccountNotFound: "The account was not found",
    CreateAccount: "Could not create account",
    CreateUser: "Could not create user",
  },
};

export default en;

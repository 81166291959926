import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { mergeArrays } from "utils/util";

export const ApolloClientProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT as string,
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (extensions?.code === "UNAUTHENTICATED") {
          window.dispatchEvent(new CustomEvent("logout"));
        }
      });
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            posts: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return mergeArrays(existing, incoming);
              },
            },
            reviewsByPost: {
              keyArgs: ["postId"],
              merge(existing = [], incoming) {
                return mergeArrays(existing, incoming);
              },
            },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

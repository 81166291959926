import { TitleProps } from "components/fonts";


export const H1 = (props: TitleProps) => {

    const style = {
        marginTop: "10px",
        color: "#18D1EF",
        fontSize: "25px",
    }

    return (
        <h4 style={props.style || style}> {props.message} </h4>
    );
}

export default H1;
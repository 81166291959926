import { ReactNode, createContext, useContext, useState } from "react";
interface RouterContextProps {
  reload: () => void;
}

export const RouterContext = createContext<RouterContextProps>({
  reload: () => {},
});

export const RouterProvider = ({ children }: { children: ReactNode }) => {
  const [_, setForceReload] = useState(0);

  return (
    <RouterContext.Provider
      value={{ reload: () => setForceReload((f) => f + 1) }}
    >
      {children}
    </RouterContext.Provider>
  );
};

export const useRouterContext = () => useContext(RouterContext);

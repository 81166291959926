import { gql, useMutation } from "@apollo/client";
import { ProfileType } from "models/Profile";

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      profile {
        id
        name
        surname
        aboutMe
        nickname
        photoUrl
        stripeAccountId
        stripeAccountReady
        customerId
        account {
          id
          email
          agreement
          roles
        }
      }
    }
  }
`;

export const useRefreshToken = () => {
  const [refreshToken, { loading, error }] = useMutation<{
    refreshToken: {
      token: string;
      profile: ProfileType;
    };
  }>(REFRESH_TOKEN);
  return { refreshToken, loading, error };
};

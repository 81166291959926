import { gql, useMutation } from "@apollo/client";
import { ProfileType } from "models/Profile";

const QUERY_ME = gql`
  mutation Me {
    me {
      token
      profile {
        id
        name
        surname
        aboutMe

        nickname
        photoUrl
        customerId
        stripeAccountId
        stripeAccountReady
        account {
          id
          email
          roles
        }
      }
    }
  }
`;

export const useMe = () => {
  const [me, { loading, error }] = useMutation<{
    me: {
      token: string;
      profile: ProfileType;
    };
  }>(QUERY_ME);
  return { me, loading, error };
};

import initSentryLogging from "./sentry";
import initGrafanaLogging from "./grafana";

export const initLogging = () => {
  const sentryOptions = {
    dsn: process.env.REACT_APP_SENTRY_DSN as string,
    apiUrl: process.env.REACT_APP_API_URL as string,
  };

  const grafanaOptions = {
    url: process.env.REACT_APP_GRAFANA_URL as string,
    app: {
      name: process.env.REACT_APP_NAME as string,
      version: process.env.REACT_APP_VERSION as string,
      environment: process.env.REACT_APP_ENVIRONMENT as string,
    },
  };

  if (sentryOptions.dsn) {
    initSentryLogging(sentryOptions);
  }
  if (grafanaOptions.url) {
    initGrafanaLogging(grafanaOptions);
  }
};

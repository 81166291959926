import { Component, ErrorInfo, ReactNode } from "react";
import { Result, Button, Col } from "antd";
import "./style.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouterContext } from "components/router/RouterProvider";

interface Props extends WithTranslation {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  static contextType = RouterContext;
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <Col className="error-page-content">
          <div className="error-page-container">
            <Result
              status="500"
              title={t("Error:SomethingWentWrong")}
              subTitle={t("Error:TryAgainLater")}
              extra={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Button
                    className="reload-btn"
                    type="primary"
                    onClick={() => window.location.reload()}
                  >
                    {t("Error:Reload")}
                  </Button>
                  <Button
                    className="reload-btn"
                    type="text"
                    onClick={() => window.location.replace("/")}
                  >
                    {t("Error:GoHome")}
                  </Button>
                </div>
              }
            />
          </div>
        </Col>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);

import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import { BaseFooter } from "./footer/BaseFooter";
import { BaseHeader } from "./header/BaseHeader";
import "./style.scss";

const { Header, Content, Footer } = Layout;

export const paths = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/verify-email",
  "/profile-form",
  "/logout",
  "/avatar",
  /^\/chat\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
];

const exceptions = [
  "help",
  "avatar",
  "stripe-account",
  "paymentMethods",
  "terms-of-use",
  "promotion",
  "contacto",
  "privacy-policy",
];
const exceptionsPattern = exceptions.join("$|");
const regex = new RegExp(`^\\/(?!${exceptionsPattern}$)[^\\/]*$`);
const noHeaderPaths = [
  ...paths,
  "/",
  "/chat",
  /\/.+\/orders/g,
  regex,
  "/admin/dashboard",
];

export const BaseLayout = (props: any) => {
  const { children } = props;
  const location = useLocation();
  const isIncluded = noHeaderPaths.some((path) => {
    if (typeof path === "string") return path === location.pathname;
    return path.test(location.pathname);
  });

  return (
    <Layout>
      {!isIncluded && (
        <Header className="header">
          <BaseHeader />
        </Header>
      )}
      <Content
        style={{ backgroundColor: "white", minHeight: "78vh", zIndex: 1 }}
      >
        {children}
      </Content>
      <Footer className="footer">
        <BaseFooter />
      </Footer>
    </Layout>
  );
};

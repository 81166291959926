import CookieConsent from "react-cookie-consent";
import "./style.scss";
import { Row } from "antd";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
export const CookieConsentModal = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      location="bottom"
      buttonText={t("CookieConsent:AcceptAll")}
      cookieName="user_consent"
      cookieValue="all"
      onAccept={() => {}}
      overlay
      setDeclineCookie
      flipButtons
      enableDeclineButton
      declineCookieValue="necessary"
      declineButtonText={t("CookieConsent:AcceptNecessary")}
      onDecline={() => {}}
      disableStyles
      containerClasses="cookie-consent-container"
      buttonClasses="cookie-consent-btn"
      contentClasses="cookie-consent-content"
      declineButtonClasses="cookie-consent-btn-cancel"
      buttonWrapperClasses="cookie-consent-btn-wrapper"
      overlayClasses="cookie-consent-overlay"
    >
      <Row className="content-row" wrap>
        <Typography variant="body1">{t("CookieConsent:Text")}</Typography>
        <Typography variant="caption">
          {t("CookieConsent:SecondText")}
        </Typography>
      </Row>
    </CookieConsent>
  );
};

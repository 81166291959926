import { RouteType } from "models/Route";
import React from "react";

export const loadable = (loader: () => Promise<any>) => {
  const LazyLoad = React.lazy(loader);
  return <LazyLoad />;
};

export const PATHS = {
  HOME: "/",
  SIGNUP: "/signup",
  LOGIN: "/login",
  LOGOUT: "/logout",
  CHANGE_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  PROFILE_FORM: "/profile-form",
  AVATAR_FORM: "/avatar",
  POST_FORM: "/posts/form",
  POST: "/posts/:id",
  FILTERED_POSTS: "/posts/filtered",
  SEARCHED_POSTS: "/posts/searched/:search",
  PROFILE: "/:nickname",
  MY_POSTS: "/:nickname/posts",
  MY_FAVORITES: "/:nickname/favorites",
  MY_ORDERS: "/:nickname/orders",
  MY_INCOME: "/:nickname/income",
  ORDER_PAGE: "/orders/:orderId",
  CHAT: "/chat",
  CHAT_ROOM: "/chat/:roomId",
  LOADING_PAGE: "/loading",
  ORDER_FORM: "/orders/form",
  ORDER_DETAILS: "/orders/details",
  STRIPE: "/stripe-account",
  STRIPE_RETURN: "/stripe/return",
  PAYMENT_METHODS: "/paymentMethods",
  POST_ORDERS: "/posts/:id/orders",
  HELP: "/help",
  TERMS_OF_USE: "/terms-of-use",
  PRIVACY_POLICY: "/privacy-policy",
  CONTACT: "/contacto",
  PROMOTE_POST: "/promotion",
  PROMOTE_POST_DETAILS: "/promotion/details",
  POSTS_FILTERS: "/posts/filter",
  TEST: "/test",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_BOOKINGS: "/admin/bookings/:accountId",
  ADMIN_ORDER_INCIDENCES: "/admin/incidences/:orderId",
  BLOCK_LIST: "/block-list",
  NOT_FOUND: "*",
  ERROR: "/error",
  // TEST: "/test",
};

export const routes: RouteType[] = [
  // {
  //   path: PATHS.TEST,
  //   element: loadable(() =>
  //     import("pages").then((module) => ({
  //       default: module.Test,
  //     }))
  //   ),
  // },

  {
    default: true,
    path: PATHS.HOME,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.HomePage,
      }))
    ),
  },
  {
    path: PATHS.ADMIN_ORDER_INCIDENCES,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.OrderIncidencesPage,
      }))
    ),
  },
  {
    path: PATHS.BLOCK_LIST,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.BlockListPage,
      }))
    ),
  },

  {
    path: PATHS.MY_INCOME,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.MyIncomePage,
      }))
    ),
  },
  {
    path: PATHS.ERROR,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.ThrowError,
      }))
    ),
  },
  {
    path: PATHS.ADMIN_BOOKINGS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.AdminBookingsPage,
      }))
    ),
  },
  {
    path: PATHS.ADMIN_DASHBOARD,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.AdminDashboardPage,
      }))
    ),
  },
  {
    path: PATHS.SEARCHED_POSTS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.SearchedPostsPage,
      }))
    ),
  },
  {
    path: PATHS.FILTERED_POSTS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.FilteredPostsPage,
      }))
    ),
  },

  {
    path: PATHS.POSTS_FILTERS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.PostsFiltersPage,
      }))
    ),
  },
  {
    path: PATHS.SIGNUP,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.AccountFormPage,
      }))
    ),
  },
  // {
  //   path: PATHS.CHANGE_PASSWORD,
  //   element: loadable(() =>
  //     import("pages").then((module) => ({
  //       default: module.ChangePasswordPage,
  //     }))
  //   ),
  // },
  // {
  //   path: PATHS.FORGOT_PASSWORD,
  //   element: loadable(() =>
  //     import("pages").then((module) => ({
  //       default: module.ForgetPasswordPage,
  //     }))
  //   ),
  // },
  {
    path: PATHS.LOGIN,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.LoginPage,
      }))
    ),
  },

  {
    path: PATHS.LOGOUT,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.Logout,
      }))
    ),
  },
  {
    path: PATHS.POST_FORM,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.PostFormPage,
      }))
    ),
  },
  {
    path: PATHS.POST,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.PostPage,
      }))
    ),
  },
  {
    path: PATHS.PROFILE,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.ProfilePage,
      }))
    ),
  },
  {
    path: PATHS.AVATAR_FORM,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.AvatarFormPage,
      }))
    ),
  },
  {
    path: PATHS.PROFILE_FORM,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.ProfileFormPage,
      }))
    ),
  },
  {
    path: PATHS.MY_POSTS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.MyPostsPage,
      }))
    ),
  },
  {
    path: PATHS.LOADING_PAGE,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.LoadingPage,
      }))
    ),
  },
  {
    path: PATHS.ORDER_FORM,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.OrderFormPage,
      }))
    ),
  },
  {
    path: PATHS.ORDER_DETAILS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.OrderDetails,
      }))
    ),
  },
  {
    path: PATHS.STRIPE,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.CreateStripeAccount,
      }))
    ),
  },
  {
    path: PATHS.STRIPE_RETURN,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.StripeReturn,
      }))
    ),
  },
  {
    path: PATHS.PAYMENT_METHODS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.PaymentMethods,
      }))
    ),
  },
  {
    path: PATHS.POST_ORDERS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.PostOrdersPage,
      }))
    ),
  },
  {
    path: PATHS.HELP,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.HelpPage,
      }))
    ),
  },
  {
    path: PATHS.TERMS_OF_USE,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.TermsOfUsePage,
      }))
    ),
  },
  {
    path: PATHS.PRIVACY_POLICY,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.PrivacyPolicyPage,
      }))
    ),
  },
  {
    path: PATHS.CONTACT,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.ContactPage,
      }))
    ),
  },
  {
    path: PATHS.PROMOTE_POST,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.PromotePostPage,
      }))
    ),
  },

  {
    path: PATHS.PROMOTE_POST_DETAILS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.PromotionDetailsPage,
      }))
    ),
  },
  {
    path: PATHS.MY_FAVORITES,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.MyFavoritesPage,
      }))
    ),
  },
  {
    path: PATHS.CHAT,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.ChatListPage,
      }))
    ),
  },
  {
    path: PATHS.CHAT_ROOM,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.ChatPage,
      }))
    ),
  },
  {
    path: PATHS.MY_ORDERS,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.MyOrdersPage,
      }))
    ),
  },
  {
    path: PATHS.ORDER_PAGE,
    element: loadable(() =>
      import("pages").then((module) => ({
        default: module.OrderPage,
      }))
    ),
  },
];

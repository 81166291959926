import { useMe } from "hooks/api/account/useMe";
import { useOauthLogin } from "hooks/api/auth/useOauthLogin";
import { useLogin } from "hooks/api/auth/useLogin";
import { ProfileType } from "models/Profile";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useRefreshToken } from "hooks/api/auth/useRefreshToken";

export interface Auth {
  token: string | null;
  profile: ProfileType | null;
  isLogged: boolean;
}

export interface AuthProps extends Auth {
  login: (loginData: LoginData) => Promise<boolean>;
  logout: () => void;
  refetchSession: () => Promise<void>;
}
export const AuthContext = createContext<AuthProps>({
  login: (_loginData: LoginData) => Promise.resolve(false),
  logout: () => {},
  profile: null,
  token: null,
  isLogged: false,
  refetchSession: () => Promise.resolve(),
});

export interface LoginData {
  email?: string;
  password?: string;
  rememberMe?: boolean;
  identityToken?: string;
}

export const AuthProvider = React.memo((props: any) => {
  const { children } = props;

  const [auth, setAuth] = useState<Auth>({
    token: null,
    profile: null,
    isLogged: false,
  });

  const { loginCred } = useLogin();
  const { oauthLogin } = useOauthLogin();
  const { me } = useMe();
  const { refreshToken } = useRefreshToken();
  useEffect(() => {
    window.addEventListener("logout", logout);
    const localToken = localStorage.getItem("token");
    if (localToken) {
      refreshToken({
        variables: {
          token: localToken,
        },
      })
        .then(({ data }) => {
          if (data) {
            const { token, profile } = data.refreshToken;
            localStorage.setItem("token", token);
            window.dispatchEvent(new CustomEvent("refreshToken"));
            setAuth({ token, profile, isLogged: true });
          }
        })
        .catch(() => {
          logout();
        });
    }

    return () => {
      window.removeEventListener("logout", logout);
    };
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    setAuth({ token: null, profile: null, isLogged: false });
  };

  const refetchSession = useCallback(async () => {
    const { data } = await me();
    if (data) {
      const { token, profile } = data.me;
      localStorage.setItem("token", token);
      setAuth({ token, profile, isLogged: true });
    } else {
      logout();
    }
  }, [me]);

  const handleLogin = useCallback(
    async (loginData: LoginData) => {
      const { email, password, rememberMe, identityToken } = loginData!;
      try {
        if (identityToken) {
          const { data } = await oauthLogin({
            variables: {
              token: identityToken,
            },
          });
          if (data) {
            const { token, profile } = data.oauth;
            localStorage.setItem("token", token);
            setAuth({ token, profile, isLogged: true });
            return true;
          }
        }
        const { data } = await loginCred({
          variables: {
            input: {
              email,
              password,
              rememberMe,
            },
          },
        });
        if (data) {
          const { token, profile } = data.login;
          localStorage.setItem("token", token);
          setAuth({ token, profile, isLogged: true });
          return true;
        }
        return false;
      } catch (error: any) {
        console.log(error);
        logout();
        throw new Error(error.message);
      }
    },
    [loginCred, oauthLogin]
  );

  const { token, profile, isLogged } = auth;
  return (
    <AuthContext.Provider
      value={{
        login: handleLogin,
        refetchSession,
        logout,
        profile,
        token,
        isLogged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

const es = {
  MetaTags: {
    Title: {
      Home: "Globalite",
      Profile: "Globalite - Perfil",
      Post: "Globalite - Publicacion",
    },
    Description: {
      Home: "¡Encuentra y alquila lo que necesitas, o alquila lo que no usas! Alquileres seguros, cómodos y sencillos entre particulares con nuestra nueva app. Introduciendo Globalite, la revolucionaria plataforma para alquilar todo tipo de productos entre particulares. Ya sea que necesites una cámara para un proyecto de fin de semana, una bicicleta para una aventura de un día o un vestido de gala para una noche, Globalite te conecta con personas en tu área que tienen justo lo que necesitas. Por otro lado, si tienes artículos que no estás utilizando, ¿por qué no ponerlos a trabajar para ti? Puedes listar tus propios artículos para alquiler, establecer tus tarifas y condiciones, y ganar dinero extra. Nuestro sistema seguro de pagos protege tanto a arrendatarios como a arrendadores, y nuestro equipo de servicio al cliente está siempre listo para ayudar. ¡Descarga Globalite ahora y descubre una nueva forma de alquilar!",
    },
    Keywords: {
      Home: "Globalite, alquilar, alquiler, producto, aplicación, día, nuevo, encontrar, propietario, arrendador, chat, gratis, fácil, seguro, particulares.",
    },
  },
  Block: {
    BlockUser: "Bloquear usuario",
    Blocked: "Bloqueado",
    BlockInfo:
      "Has bloqueado a este usuario o este usuario te ha bloqueado.\nPor favor, comprueba tu lista de bloqueados.",
    BlockQuestion: "¿Estás seguro de que quieres bloquear a este usuario?",
    UnblockQuestion: "¿Estás seguro de que quieres desbloquear a este usuario?",
    BlockList: "Lista de bloqueados",
    Blockeds: "Bloqueados",
    Unblock: "Desbloquear",
    NoBlocks: "No tienes usuarios bloqueados.",
  },
  CheckInOut: {
    Status: {
      checked_in: "Check-in realizado",
      checked_out: "Check-out realizado",
      checkin_requested: "Check-in solicitado",
      checkout_requested: "Check-ou solicitado",
    },
  },
  Payment: {
    Authorization: "Autorización de pago",
    AuthorizationDescription:
      "Por favor, autorice el pago. Puede que se deba realizar en una aplicación externa.",
    WaitingAuthorization: "Esperando autorización...",
  },
  CookieConsent: {
    Text: "Este sitio web utiliza cookies para mejorar tu experiencia.",
    SecondText:
      "Las cookies son utilizadas para recoger información sobre cómo interactúas con nuestro sitio web y nos permiten recordarte. Utilizamos esta información para mejorar y personalizar tu experiencia de navegación y para analíticas y métricas sobre nuestros visitantes.",
    AcceptNecessary: "Aceptar cookies necesarias",
    AcceptAll: "Aceptar todas las cookies",
  },
  Admin: {
    CancelBail: "Cancelar fianza",
    promotionRate: "Tarifa de promoción (€/semana)",
    ConfirmPaymentQuestion: "¿Estás seguro de que quieres confirmar este pago?",
    ConfirmPayment: "Confirmar pago",
    ChargeParcialBail: "Cobrar fianza parcial",
    ChargeTotalBail: "Cobrar fianza total",
    ChargeBailQuestion: "¿Estás seguro de que quieres cobrar la fianza?",
    CancelPayment: "Cancelar pago",
    CancelPaymentQuestion: "¿Estás seguro de que quieres cancelar el pago?",
    ResolveIncidences: "Resolver incidencias",
    ResolveOrderIncidences: "Resolver incidencias de reserva",
    DownloadImages: "Descargar imágenes...",
    OrdersWithIncidences: "Reservas con incidencias",
    Incidences: "Incidencias",
    FinnishOrder: "Finalizar reserva",
    ManageOrderQuestion: "¿Estás seguro de que quieres modificar esta reserva?",
    AcceptOrder: "Aceptar reserva",
    RejectOrder: "Rechazar reserva",
    DeleteOrder: "Eliminar reserva",
    CancelOrder: "Cancelar reserva",
    ManageOrder: "Gestionar reserva",
    Month: "Mes",
    commission: "Comisión",
    seller: "Vendedor",
    stripe: "Stripe",
    revenue: "Ingresos",
    stripeBail: "Fianza",
    Orders: "Reservas",
    Revenue: "Ingresos",
    Accepted: "Aceptadas",
    Rejected: "Rechazadas",
    Pending: "Pendientes",
    Cancelled: "Canceladas",
    Finished: "Finalizadas",
    accepted: "Aceptada",
    rejected: "Rechazada",
    pending: "Pendiente",
    cancelled: "Cancelada",
    finished: "Finalizada",
    TotalRevenue: "Ingresos totales",
    TotalComission: "Comisión total",
    Seller: "Vendedor",
    Buyer: "Comprador",
    Messages: "Mensajes",
    DeleteReportQuestion: "¿Estás seguro de que quieres eliminar este informe?",
    Bookings: "Reservas",
    Stats: "Estadísticas",
    Chats: "Chats",
    Users: "Usuarios",
    NotAdmin: "No eres un administrador",
    ConfigParams: "Parámetros de configuración",
    stripeComission: "Comisión",
    jwtTokenSecret: "JWT token secret",
    AccountReports: "Cuentas",
    PostReports: "Anuncios",
    Reports: "Informes",
    ReviewReports: "Reseñas",
    PostReportsModal: "Informes de anuncios",
    AccountReportsModal: "Informes de cuentas",
    ReviewReportsModal: "Informes de reseñas",
    ReportedTimes: "Número de informes: {{count}}",
    Cancel: "Cancelar",
    Delete: "Eliminar",
    Reason: "Motivo",
    ReportQuestion: "¿Estás seguro de que quieres enviar este informe?",
  },
  Filters: {
    SearchedPosts: "Mostrando resultados para '{{search}}'. Total: {{total}}",
    NoPostsFiltered:
      "No hemos encontrado ningúna publicación con esos filtros.",
    SelectCategory: "Elige una categoría:",
    From: "Desde",
    yes: "Sí",
    Yes: "Sí",
    No: "No",
    no: "No",
    To: "Hasta",
    All: "Todo",
    Filter: "Filtrar",
    red: "Rojo",
    blue: "Azul",
    green: "Verde",
    yellow: "Amarillo",
    orange: "Naranja",
    purple: "Morado",
    black: "Negro",
    white: "Blanco",
    grey: "Gris",
    brown: "Marrón",
    pink: "Rosa",
    silver: "Plata",
    gold: "Oro",
    gasoline: "Gasolina",
    diesel: "Diesel",
    hybrid: "Híbrido",
    electric: "Eléctrico",
    other: "Otro",
  },
  Footer: {
    Profile: "Perfil",
    Home: "Inicio",
    Orders: "Reservas",
    Admin: "Admin",
  },
  Stripe: {
    SetUpIntent: {
      Succeded: "Tu tarjeta ha sido añadida con éxito",
      Processing: "Procesando...",
      Error:
        "Ha habido un error al añadir tu tarjeta. Por favor, prueba de nuevo más tarde.",
    },
    CreateExpressAccount: {
      Title: "Acciones necesarias",
      Description:
        "Necesitas crear una cuenta de Stripe para poder crear posts y recibir dinero de tus clientes. Por favor, haz clic en el botón de abajo para redirigirte a la plataforma de Stripe para tu registro.",
      GoStripe: "Ir a Stripe",
    },
    PaymentMethods: {
      Title: "Métodos de pago",
      Hide: "Ocultar",
      Pay: "Pagar",
      AddNew: "Añadir nuevo método de pago",
      Select: "Seleccione un método de pago para pagar su pedido",
    },
  },
  Colors: {},
  Order: {
    Request: "Solicitud",
    Approval: "Aprobación",
    PaymentCard: "Tarjeta de pago",
    PaymentId: "ID de pago",
    PaymentDate: "Fecha de pago",
    ProductPrice: "Precio del producto",
    OrderPrice: "Precio de la reserva",
    ManagementCost: "Gastos de gestión",
    StripeCost: "Gastos dinámicos",
    OrderStatus: {
      accepted: "ACEPTADO",
      rejected: "RECHAZADO",
      pending: "PENDIENTE",
      canceled: "CANCELADO",
      finished: "FINALIZADO",
      requires_action: "REQUIERE ACCIÓN",
      failed: "FALLIDO",
      accepted_payment_failed: "PAGO ACEPTADO FALLIDO",
      no_payment: "SIN PAGO",
    },
    PossiblyRecreatePayments:
      "Es posible que el arrendatario pueda recrear los pagos, pero si cancela esta reserva, no se podrán recrear los pagos.",
    CancelOrderQuestion: "¿Estás seguro de que quieres cancelar esta reserva?",
    RecreatePayment: "Reintentar pago",
    RentedBy: "Alquilado por",
    BailClaimed: "Fianza cobrada",
    ResolveIncidencesQuestion:
      "¿Estás seguro de que quieres resolver las incidencias?",
    CancelPaymentQuestion: "¿Estás seguro de que quieres cancelar este pago? ",
    ChargeBail: "Cobrar fianza",
    Bail: "Fianza",
    ChargeBailQuestion: "¿Estás seguro de que quieres cobrar esta fianza?",
    ParcialBailQuestion:
      "¿Cuánta fianza quieres cobrar al usuario? (Max: {{max}})",
    QRScanner: "Escáner QR",
    QRCode: "Código QR",
    OrderData: "Datos de la reserva",
    Incidence: "Incidencia",
    CreateIncidence: "Reportar incidencia",
    BailInfo:
      "La fianza se devolverá al finalizar la reserva, excepto si existe alguna incidencia que se valorará la situación.",
    WhatDayYouWantToRent: "¿Qué día quieres alquilarlo?",
    WhatTime: "¿A qué hora?",
    Requests: "Solicitudes",
    CancelOrder: "Cancelar reserva",
    AddPaymentMethod: "Añadir método de pago",
    TimeUntilEnd: "Tiempo hasta el final de la reserva",
    TimeUntilStart: "Tiempo hasta el comienzo de la reserva",
    Status: "Estado",
    Buyer: "Arrendatario",
    Seller: "Arrendador",
    Cancelled: "Canceladas",
    Accepted: "Aceptadas",
    Pending: "Pendientes",
    Rejected: "Rechazadas",
    Finished: "Finalizadas",
    NoOrders: "No hay reservas todavía",
    NoAccepted: "No hay reservas aceptadas todavía",
    NoPending: "No hay reservas pendientes todavía",
    NoRejected: "No hay reservas rechazadas todavía",
    NoFinished: "No hay reservas finalizadas todavía",
    UpdateOrder: "Actualizar solicitud",
    CreateOrder: "Crear nueva solicitud",
    StartAt: "Fecha inicio",
    Duration: "Duración",
    HowMuchTime: "¿Cuánto tiempo necesitas?",
    day: "día",
    hour: "hora",
    minute: "minuto",
    week: "semana",
    second: "segundo",
    NumberOfday: "Número de días",
    NumberOfhour: "Número de horas",
    NumberOfminute: "Número de minutos",
    NumberOfweek: "Número de semanas",
    Price: "Precio",
    StartTime: "Hora inicio",
    EndTime: "Hora fin",
    Per: "por {{timeUnit}}",
    PostPrice: "El precio del post es",
    Reservation: "Tu reserva",
    TotalPrice: "Precio total",
    Details: "Detalles de la solicitud",
    ContinueToPayment: "Continuar al pago",
  },
  Categories: {
    rollers: "Patinetes",
    music_instuments: "Música e Instrumentos",
    vehicles_motor: "Vehículos y Motor",
    technology_electronics: "Tecnología y Electrónica",
    home_garden: "Hogar y Jardín",
    sports_leisure: "Deportes y Ocio",
    fashion_accessories: "Moda y Accesorios",
    enterainment_culture: "Entretenimiento y Cultura",
    housing_premises: "Viviendas y Locales",
    events_parties: "Eventos y Fiestas",
    industry_services: "Industria y Servicios",
    water_activities: "Actividades acuáticas",
    cars: "Coches",
    motorcycles: "Motos",
    bicycles: "Bicicletas",
    motors_accessories: "Motores y Accesorios",
    tv_audio_photo: "TV, Audio y Foto",
    mobiles_telephony: "Móviles y Telefonía",
    computers_electronics: "Ordenadores y Electrónica",
    console_videogames: "Consolas y Videojuegos",
    appliances: "Electrodomésticos",
    furniture: "Muebles",
    water_sports: "Deportes acuáticos",
    mountain_sports: "Deportes de montaña",
    snow_sports: "Deportes de nieve",
    football: "Fútbol",
    tennis: "Tenis",
    padel: "Padel",
    basketball: "Baloncesto",
    voleyball: "Voleibol",
    other_sports: "Otros deportes",
    fashion: "Moda",
    jewelry_accessories: "Joyería y Accesorios",
    flamenco_wedding_special: "Vestidos de flamenco, bodas y especiales",
    costumes: "Disfraces",
    cinema_books_music: "Cine y libros",
    music_instruments: "Música e Instrumentos",
    children_babies: "Niños y Bebés",
    animals_pets: "Animales y Mascotas",
    events_weddings_parties: "Eventos, bodas y fiestas",
    event_party_furniture: "Mobiliario para eventos y fiestas",
    catering: "Catering",
    fishing_hunting: "Pesca y Caza",
    natucial_vessels: "Embarcaciones de recreo",
    nautical_accessories: "Accesorios náuticos",
    diving_wetsuits: "Buceo y trajes de neopreno",
    travel: "Viajes",
    beach: "Playa",
    construction_renovations: "Construcción y Reformas",
    industry_agriculture: "Industria y Agricultura",
    services: "Servicios",
    orthopedic_furniture: "Mobiliario ortopédico",
    no_motor: "Sin motor",
    motor: "Motor",
    technology: "Tecnología",
    motorbikes: "Motos",
    pcs: "Ordenadores",
    phones: "Teléfonos",
    realstate: "Inmuebles",
    houses: "Casas",
    flats: "Pisos",
    premises: "Locales",
    books: "Libros",
    ligthsound: "Iluminación y sonido",
    sports: "Deportes",
    material: "Material",
    space: "Espacios",
    tvs: "TVs",
    other: "Otro",
    flamenco_wedding_dresses: "Vestidos de flamenca y de novia",
    camping_accessories: "Accesorios de camping",
    furniture_sofas: "Muebles y sofás",
    nautical_boats: "Náutica y barcos",
    animals_pets_accessories: "Animales, mascotas y accesorios",
    travel_accessories: "Accesorios de viaje",
    beach_accessories: "Accesorios de playa",
    others: "Otros",
  },
  Promotion: {
    PromotionStartEnd:
      "La fecha de inicio de la promoción es {{startDate}} y la fecha de fin es {{endDate}}.",
    AlreadyPromoted: "Este post ya está promocionado. ",
    Pay: "Pagar",
    Days: "Número de días",
    Type: "Tipo de promoción",
    Continue: "Continuar",
    TotalPrice:
      "El precio total de la promoción es: {{rate}}€ x {{days}} días = {{price}}€",
    PromotePost: "Promoción para {{postTitle}}",
    RatesDescription:
      "Este proceso puede suponer gastos adicionales para el anunciante. La tarifa de promoción es de {{rate}}€ por semana, es decir, {{perDay}}€ por día.",
    WhichType: "¿Qué tipo de promoción quieres?",
    StartDate: "Fecha inicio",
    EndDate: "Fecha fin",
    HowMuchTime: "¿Cuánto tiempo quieres promocionar tu post?",
  },
  Review: {
    NoRating: "No hay puntuación todavía.",
    TotalReviews: "Número de reseñas: {{total}}",
    TotalPosts: "Número de anuncios: {{total}}",
    DeleteReview: "Eliminar reseña",
    DeleteReviewQuestion: "¿Estás seguro de que quieres eliminar esta reseña?",
    Rating: "Puntuación",
    Review: "Reseña",
    AddReview: "Añadir reseña",
    NoReviews: "No hay reseñas todavía.",
    Reviews: "Reseñas",
    Message: "Mensaje",
  },
  Post: {
    LocationInput: {
      Description: "Puedes escribir una dirección o seleccionarla en el mapa.",
    },
    Create: "Crear anuncio",
    Bail: "Fianza",
    DeleteQuestion: "¿Estás seguro de que quieres eliminar este anuncio?",
    Report: "Reportar",
    minute: "min",
    hour: "h",
    day: "día",
    Details: "Detalles",
    PublicationDate: "Fecha de publicación",
    Minute: "Minuto",
    Hour: "Hora",
    Day: "Día",
    Promote: "Promocionar",
    Rent: "Alquilar",
    You: "Tú",
    PostedBy: "Publicado por",
    Delete: "Eliminar",
    Edit: "Editar",
    Bookings: "Reservas",
    Model: "Modelo",
    Brand: "Marca",
    UpdatePost: "Actualizar anuncio",
    CreatePost: "Crear nuevo anuncio",
    Hps: "CV",
    Title: "Título",
    Description: "Descripción",
    Price: "Precio",
    Category: "Categoría",
    Images: "Imágenes",
    Currency: "Moneda",
    Location: "Ubicación",
    TimeUnit: "Unidad de tiempo",
  },
  Home: {
    NoPostsAvailable: "No hay publicaciones disponibles.",
    CreateOne: "¡Crea una nueva!",
    Recent: "Recientes",
    Old: "Más antiguos",
    Expensive: "Mayor precio",
    Cheap: "Menor precio",
    All: "Todo",
    Favorites: "Favoritos",
    CreatePost: "Crear publicación",
    Trending: "Tendencias",
    Filter: "Filtrar",
    Search: "Buscar",
    ForYou: "Para ti",
    Spotlight: "Destacados",
    Promoted: "Promocionados",
    Welcome: "Bienvenido",
    Home: "Inicio",
    About: "Acerca de",
    Contact: "Contacto",
    Profile: "Perfil",
    HomePage: "Esta es la página de inicio",
    Login: "Iniciar sesión",
    Logout: "Cerrar sesión",
    SignUp: "Registrarse",
    Back: "Volver",
    SeeMore: "Ver más",
  },
  Common: {
    Upload: "Subir",
    Time: "Hora",
    Back: "Atrás",
    Home: "Inicio",
    About: "Acerca de",
    Contact: "Contacto",
    Profile: "Perfil",
    HomePage: "Esta es la página de inicio",
    DNI: "DNI",
    Skip: "Omitir",
    Name: "Nombre",
    Surname: "Apellidos",
    Address: "Dirección",
    BirthDate: "Fecha de nacimiento",
    Gender: "Género",
    Male: "Hombre",
    Female: "Mujer",
    NoGender: "Prefiero no decirlo",
    Cancel: "Cancelar",
    Date: "Fecha",
    FullName: "Nombre completo",
    Password: "Contraseña",
    Done: "Hecho",
    EmailAddress: "Dirección de correo electrónico",
    Continue: "Continuar",
    Message: "Mensaje",
    Email: "Email",
    SaveChanges: "Guardar cambios",
  },

  Form: {
    ChangePassword: {
      Title: "Cambiar contraseña",
      ConfirmOld: "Confirme escribiendo la contraseña antigua",
    },
    ForgotPassword: {
      Title: "Olvidé mi contraseña",
      Question: "¿Olvidó su contraseña?",
      Description:
        "Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.",
    },
    PasswordConfirmation: "Confirmación de contraseña",
    SignInTerms: "Al registrarse, acepta los",
    TermsAndConditions: "Términos y condiciones",
    SignUp: "Registrarse",
    AlreadyHaveAccount: "¿Ya tienes una cuenta?",
  },
  Account: {
    Create: {
      Title: "Crear una nueva cuenta",
    },
    DeleteAccount: "Eliminar cuenta",
    DeleteAccountModalText:
      "Si deseas eliminar tu cuenta escribe 'delete {{nickname}}' debajo. Esta acción no se puede deshacer. Todos tus datos serán eliminados.",
    DeleteAccountModalText1:
      "Si deseas eliminar tu cuenta escribe debajo la siguiente frase:",
    DeleteAccountModalText2:
      "Esta acción no se puede deshacer. Todos tus datos serán eliminados.",
    DeleteNickname: "delete {{nickname}}",
  },
  Help: {
    LegalContent: "Contenido legal",
    TermsAndConditions: {
      Title: "Términos y condiciones",
      Content1:
        "Bienvenido a Globalite. Al utilizar nuestro sitio web, usted está de acuerdo en cumplir con estos términos y condiciones de uso. Si no está de acuerdo con estos términos y condiciones, por favor no utilice nuestro sitio web.",
      Content2:
        "Globalite es una plataforma en línea que permite a los usuarios publicar y alquilar productos entre ellos. Nosotros no somos propietarios ni vendemos ningún producto publicado en nuestro sitio web. Cualquier transacción que realice con un anunciante es su responsabilidad exclusiva.",
      Content3:
        "Al utilizar nuestro sitio web, usted acepta que es responsable de todos los productos que publique y de todas las transacciones que realice. Los usuarios se comprometen a utilizar  de manera responsable, respetuosa y legal. Queda estrictamente prohibido utilizar la aplicación para fines ilegales, abusivos o fraudulentos.",
      Content4:
        "Globalite se reserva el derecho de rechazar o eliminar cualquier anuncio o usuario que consideremos inapropiado o que viole nuestros términos y condiciones de uso.",
      Content5:
        "Globalite no garantiza la calidad, seguridad o legalidad de los productos publicados en nuestro sitio web. Usted acepta que utiliza nuestro sitio web y realiza cualquier transacción bajo su propio riesgo.",
      Content6:
        "Globalite no se hace responsable de cualquier daño o pérdida que surja de su uso de nuestro sitio web o de cualquier transacción que realice con un anunciante.",
      Content7:
        "Globalite se reserva el derecho de modificar estos términos y condiciones de uso en cualquier momento. Por favor revise estos términos y condiciones de forma regular para estar al tanto de cualquier cambio.",
      Content8:
        "Estos términos y condiciones de uso se rigen por las leyes de España. Cualquier disputa que surja del uso de nuestro sitio web será resuelta exclusivamente por los tribunales de España.",
      Content9:
        "Si tiene alguna pregunta sobre estos términos y condiciones de uso, por favor póngase en contacto con nosotros a través de",
      OurForm: "nuestro formulario.",
    },
    PrivacyPolicy: "Política de privacidad",
    ContactUs: "Contáctenos",
    Contact: "Contacto",
    FAQ: "Preguntas frecuentes FAQ",
    Submit: "Enviar",
  },
  Profile: {
    UserNotFound: "Usuario no encontrado",
    UserNotFoundInfo: "El usuario con nickname '{{nickname}}' no existe.",
    MyIncome: "Mis ingresos",
    UserPosts: "Publicaciones de {{name}}",
    NoFavorites: "No tienes favoritos",
    MyFavorites: "Mis favoritos",
    EditProfile: "Editar perfil",
    MyPosts: "Mis publicaciones",
    MyBookings: "Mis reservas",
    NeedHelp: "¿Necesitas ayuda?",
    CreateProfile: "Crear perfil",
    AboutMe: "Sobre mí",
    ZipCode: "Código postal",
    City: "Ciudad",
    ProfileImage: "Imagen de perfil",
    ProfileImageFor: "Imagen de perfil para",
    LogOut: "Cerrar sesión",
  },
  Auth: {
    NeedLogin: "Registro necesario",
    Required: "Necesitas iniciar sesión para acceder a esta página",
    SignIn: {
      RememberMe: "Recuérdame",
      Title: "Iniciar sesión",
      Continue: "Inicie sesión para continuar",
      With: "Iniciar sesión con",
      Description:
        "Ingrese su dirección de correo electrónico y contraseña para acceder a su cuenta.",
    },
  },
  Google: {
    SignIn: "Iniciar sesión con Google",
  },
  Apple: {
    SignIn: "Iniciar sesión con Apple",
  },
  ExtraAttributes: {
    length: "Longitud",
    width: "Ancho",
    height: "Altura",
    Distance: "Distancia",
    mileage: "Kilometraje",
    year: "Año",
    color: "Color",
    fuel: "Combustible",
    model: "Modelo",
    doorsnumber: "Puertas",
    seatsnumber: "Asientos",
    brand: "Marca",
    hps: "CVs",
    cpu: "CPU",
    ram: "RAM",
    storage: "Almacenamiento",
    screen: "Pantalla",
    os: "SO",
    screensize: "Tamaño de pantalla",
    screenresolution: "Resolución de pantalla",
    gpu: "GPU",
    cpucores: "Núcleos CPU",
    camerapixels: "Píxeles cámara",
    furnished: "Amueblado",
    elevator: "Ascensor",
    publisher: "Editorial",
    edition: "Edición",
    bathroomsnum: "Baños",
    floorsnum: "Plantas",
    roomsnum: "Habitaciones",
    area: "Área",
    roofed: "Cubierta",
    smarttv: "Smart TV",
    size: "Talla",
    parking: "Plaza de aparcamiento",
  },
  Error: {
    SomethingWentWrong: "¡Vaya! Algo salió mal",
    TryAgainLater: "Inténtalo de nuevo más tarde",
    Reload: "Recargar",
    GoHome: "Ir a la página de inicio",

    RequriedRating: "Por favor, introduzca una valoración",
    RequiredLocation: "Por favor, introduzca la ubicación",
    RequiredName: "Por favor, introduzca su nombre",
    RequiredSurname: "Por favor, introduzca sus apellidos",
    RequiredType: "Por favor, seleccione un tipo",
    RequiredDate: "Por favor, introduzca la fecha",
    RequiredEmail: "Por favor, introduzca su correo electrónico",
    RequiredMessage: "Por favor, introduzca su mensaje",
    RequiredStartDate: "Por favor, introduzca la fecha de inicio",
    RequiredDuration: "Por favor, introduzca la duración",
    RequiredTitle: "Por favor, introduzca el título",
    MinTitle: "El título debe tener al menos {{min}} caracteres",
    RequiredDescription: "Por favor, introduzca la descripción",
    MinDescription: "La descripción debe tener al menos {{min}} caracteres",
    RequiredCategory: "Por favor, seleccione una categoría",
    RequiredPrice: "Por favor, introduzca el precio",
    RequiredCurrency: "Por favor, seleccione una moneda",
    RequiredTimeUnit: "Por favor, seleccione una unidad de tiempo",
    RequiredReason: "Por favor, introduzca el motivo",

    EmailAlreadyInUse: "La dirección de correo electrónico ya está en uso",
    InvalidEmail: "La dirección de correo electrónico no es válida",
    InvalidPassword: "La contraseña no es válida",
    InvalidPasswordConfirmation: "La confirmación de contraseña no es válida",
    PasswordTooShort: "La contraseña es demasiado corta",
    PasswordTooLong: "La contraseña es demasiado larga",
    PasswordsDoNotMatch: "Las contraseñas no coinciden",
    UserNotFound: "El usuario no se encontró",
    WrongPassword: "La contraseña es incorrecta",
    AccountNotFound: "La cuenta no se encontró",
    CreateAccount: "No se pudo crear la cuenta",
    CreateUser: "No se pudo crear el usuario",
  },
};

export default es;

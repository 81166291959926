import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

type InitGrafanaLoggingOptions = {
  url: string;
  app: {
    name: string;
    version: string;
    environment: string;
  };
};

const initGrafanaLogging = ({ url, app }: InitGrafanaLoggingOptions) => {
  initializeFaro({
    url,
    app,
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations(),

      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      new TracingInstrumentation(),
    ],
  });
};

export default initGrafanaLogging;

import { Typography } from "@mui/material";
import { Col, Row } from "antd";
import { AuthContext } from "components/auth/authProvider";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineHome } from "react-icons/ai";
import { BsChatLeft } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";
import { paths } from "../BaseLayout";
import "./style.scss";
import { IoCalendarOutline } from "react-icons/io5";
import { IsAdminOrSupport } from "utils/util";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

export const BaseFooter = () => {
  const { profile } = useContext(AuthContext);
  const location = useLocation();
  const { t } = useTranslation();

  const isAdmin = IsAdminOrSupport(profile);
  const authsIncluded = !paths.includes(location.pathname);
  return (
    <Row
      justify="space-between"
      align="bottom"
      style={{ height: "100%", marginTop: "1vh" }}
    >
      <Col className="footer-col">
        <Link to="/">
          <AiOutlineHome
            className="footer-icon"
            color={location.pathname === "/" ? "#18D1EF" : "#565656"}
          />
          <Typography
            className="footer-text"
            color={location.pathname === "/" ? "#18D1EF" : "#565656"}
          >
            {t("Footer:Home")}
          </Typography>
        </Link>
      </Col>
      {authsIncluded && (
        <>
          <Col className="footer-col">
            <Link to="/chat">
              <BsChatLeft
                className="footer-icon"
                color={location.pathname === "/chat" ? "#18D1EF" : "#565656"}
              />
              <Typography
                className="footer-text"
                color={location.pathname === "/chat" ? "#18D1EF" : "#565656"}
              >
                Chat
              </Typography>
            </Link>
          </Col>
          <Col className="footer-col">
            <Link to={`/${profile?.nickname}/orders`}>
              <IoCalendarOutline
                className="footer-icon"
                color={
                  location.pathname === `/${profile?.nickname}/orders`
                    ? "#18D1EF"
                    : "#565656"
                }
              />
              <Typography
                className="footer-text"
                color={
                  location.pathname === `/${profile?.nickname}/orders`
                    ? "#18D1EF"
                    : "#565656"
                }
              >
                {t("Footer:Orders")}
              </Typography>
            </Link>
          </Col>

          {isAdmin && (
            <Col className="footer-col">
              <Link to="/admin/dashboard">
                <MdOutlineAdminPanelSettings
                  className="footer-icon"
                  color={
                    location.pathname === "/admin/dashboard"
                      ? "#18D1EF"
                      : "#565656"
                  }
                />
                <Typography
                  className="footer-text"
                  color={
                    location.pathname === "/admin/dashboard"
                      ? "#18D1EF"
                      : "#565656"
                  }
                >
                  {t("Footer:Admin")}
                </Typography>
              </Link>
            </Col>
          )}
          <Col className="footer-col">
            <Link to={`/${profile?.nickname}`}>
              <CgProfile
                className="footer-icon"
                color={
                  location.pathname === `/${profile?.nickname}`
                    ? "#18D1EF"
                    : "#565656"
                }
              />
              <Typography
                className="footer-text"
                color={
                  location.pathname === `/${profile?.nickname}`
                    ? "#18D1EF"
                    : "#565656"
                }
              >
                {t("Footer:Profile")}
              </Typography>
            </Link>
          </Col>
        </>
      )}
    </Row>
  );
};

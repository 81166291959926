import { gql, useMutation } from "@apollo/client";
import { ProfileType } from "models/Profile";

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
      profile {
        id
        name
        surname
        aboutMe
        nickname
        photoUrl
        stripeAccountId
        stripeAccountReady
        customerId
        account {
          id
          email
          agreement
          roles
        }
      }
    }
  }
`;

export const useLogin = () => {
  const [loginCred, { loading, error }] = useMutation<{
    login: {
      roles: string[];
      token: string;
      profile: ProfileType;
    };
  }>(LOGIN);
  return { loginCred, loading, error };
};

import { Button, Col, Result } from "antd";
import { BaseLayout } from "components/layouts";
import { RouteType } from "models/Route";
import { LoadingPage } from "pages/LoadingPage";
import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

export interface RouterProps {
  routes: RouteType[];
}
export const LazyRouter = React.memo<RouterProps>((props) => {
  const { routes } = props;
  const { t } = useTranslation();
  return (
    <Router>
      <BaseLayout>
        <React.Suspense fallback={<LoadingPage />}>
          <Routes>
            {routes.map((props) => {
              return <Route key={"route-" + props.path} {...props} />;
            })}

            <Route
              path="*"
              element={
                <Col className="error-page-content">
                  <div className="error-page-container">
                    <Result
                      status="500"
                      title={t("Error:SomethingWentWrong")}
                      subTitle={t("Error:TryAgainLater")}
                      extra={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Button
                            className="reload-btn"
                            type="text"
                            onClick={() => window.location.replace("/")}
                          >
                            {t("Error:GoHome")}
                          </Button>
                        </div>
                      }
                    />
                  </div>
                </Col>
              }
            />
          </Routes>
        </React.Suspense>
      </BaseLayout>
    </Router>
  );
});

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { es, en } from "utils/lang";

export const defaultNS = false;
export const resources = {
  en,
  es,
} as const;

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    ns: Object.keys(en),
    defaultNS,
    resources,
  });

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Stripe, loadStripe } from "@stripe/stripe-js";

interface StripeContextProps {
  stripe: Stripe | null;
}

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
);

const StripeContext = createContext<StripeContextProps>({
  stripe: null,
});
export const StripeProvider = ({ children }: { children: ReactNode }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    stripePromise.then(setStripe);
  }, []);

  return (
    <StripeContext.Provider value={{ stripe }}>
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeContext = () => useContext(StripeContext);
